import React, { Component } from 'react';
import { FeedsCardContainer } from './FeedsCardStyle';
import numeral from 'numeral';
import Link from 'next/link';
import {
  getImageAvatar,
  getImageMobileFull,
  getImageMobileList,
  repairLogoUrl
} from '../../util';
import { inject, observer } from 'mobx-react';
import { locationGenerator } from '../../topic/TopicPage/CategoryMeta/CheliangMeta';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import { TOPIC_CATEGORY } from '../../mobx/TopicStore';
import { JOB_SUB_CATEGORY } from '../../constants/category/job';
import { RENTAL_SUB_CATEGORY } from '../../constants/category/rental';
import { ERSHOU_SUB_CATEGORY } from '../../constants/category/usedItems';
import { handleTitle } from '../../topic/TopicTitle';
import moment from 'moment';
import styled from 'styled-components';
import Router from 'next/router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FeedComments from '../../components/FeedComments';

const avatarImg = '../../static/avatar.png';
const Comment = styled.div`
  display: flex;
  justify-content: flex-end;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 5px;
  padding: 5px 0;
  .comment-icon {
    font-size: 15px;
    margin-right: 5px;
  }
`;

export const renderTitle = (title, category_name, sub_category_name) => {
  switch (category_name) {
    case TOPIC_CATEGORY.JOB: {
      if (sub_category_name === JOB_SUB_CATEGORY.QIUZHI) {
        return `【求职】${title}`;
      } else {
        return `【招聘】${title}`;
      }
    }

    case TOPIC_CATEGORY.FANG: {
      switch (sub_category_name) {
        case RENTAL_SUB_CATEGORY.QIUZU: {
          return `【求租】${title}`;
        }
        case RENTAL_SUB_CATEGORY.ZHAOSHIYOU: {
          return `【找室友】${title}`;
        }
        default: {
          return title;
        }
      }
    }
    case TOPIC_CATEGORY.USED: {
      switch (sub_category_name) {
        case ERSHOU_SUB_CATEGORY.QIUGOU: {
          return `【求购】${title}`;
        }
        case ERSHOU_SUB_CATEGORY.XINHUOJIAOYI: {
          return `【全新】${title}`;
        }
        case ERSHOU_SUB_CATEGORY.ERSHOUJIAOYI: {
          return `【二手】${title}`;
        }
        case ERSHOU_SUB_CATEGORY.MIANFEIZENGSONG: {
          return `【免费】${title}`;
        }
        default: {
          return title;
        }
      }
    }

    default: {
      return title ? title : '';
    }
  }
};

@inject('uiStore')
@observer
class FeedsCard extends Component {
  state = {
    lightboxIsOpen: false
  };

  openLightbox = (event, index = 0) => {
    event.preventDefault();
    const { topic } = this.props;
    const pswpElement = document.querySelectorAll('.pswp')[0];
    // build items array
    const items = topic.images.map(image => {
      return {
        src: getImageMobileFull(image),
        w: 800,
        h: 1200
      };
    });

    const options = {
      index: index
    };

    // Initializes and opens PhotoSwipe
    const gallery = new PhotoSwipe(
      pswpElement,
      PhotoSwipeUI_Default,
      items,
      options
    );
    gallery.init();
  };

  renderSalaryTitle = salary => {
    switch (salary) {
      case 'lowest': {
        return '最低工资';
      }
      case 'average': {
        return '高于最低工资';
      }
      default: {
        return '面议';
      }
    }
  };

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    let price = 0,
      rent_per_week = 0,
      salary = 0,
      type = 0;
    const {
      topic: {
        city,
        content,
        time,
        updated_at,
        id,
        images,
        merchant,
        category_name,
        sub_category_name,
        comments_count = 0,
        comment
      },
      topic,
      showOptions
    } = this.props;
    if (topic.meta) {
      price = topic.meta.price;
      rent_per_week = topic.meta.rent_per_week;
      salary = topic.meta.salary;
      type = topic.meta.type;
    }
    const user = this.props.topic.user || this.props.user;
    let name,
      avatar = '';
    if (merchant) {
      name = merchant.name;
      if (merchant.logo) {
        avatar = repairLogoUrl(merchant.logo);
      } else {
        avatar = avatarImg;
      }
    } else {
      name = user && user.name ? user.name : '神马用户';
      if (user && user.image) {
        avatar = getImageAvatar(user && user.image);
      } else {
        avatar = avatarImg;
      }
    }

    const topicTitle = renderTitle(
      handleTitle(topic),
      category_name,
      sub_category_name
    );

    const TopicTitle = topicTitle === '' ? null : <h4>{topicTitle}</h4>;
    return (
      <FeedsCardContainer className="FeedsCardContainer">
        <Link href="/topic/[id]" as={`/topic/${id}`} scroll>
          <div>
            <div className="feeds-topic-header">
              <div className="feeds-topic-owner-info">
                <div>
                  <img src={avatar} alt="" />
                </div>
                <div className="feeds-topic-owner-username">
                  <div className="options">
                    <span>{name} </span>{' '}
                    {showOptions && (
                      <i
                        onClick={e => {
                          e.preventDefault();
                          this.props.onOptionsClick &&
                            this.props.onOptionsClick(id);
                        }}
                        className="iconfont icon-down_arrow_icon"
                      />
                    )}{' '}
                  </div>
                  <span>
                    更新于 {time || moment(updated_at).format('YYYY年MM月DD日')}
                  </span>
                </div>
              </div>
              <div className="feeds-topic-title">{TopicTitle}</div>
              {city && (
                <div className="feeds-topic-location-cost">
                  <p>
                    <i className="iconfont icon-location" />
                    {locationGenerator(topic)}
                  </p>
                  {category_name === 'fang' && rent_per_week > 0 && (
                    <p className="feeds-topic-cost">
                      ${rent_per_week && numeral(rent_per_week).format('0,0')}
                    </p>
                  )}

                  {(category_name === 'cheliang' ||
                    (category_name === 'ershou' && type !== '免费送')) &&
                    price > 0 && (
                      <p className="feeds-topic-cost">
                        ${numeral(price).format('0,0')}
                      </p>
                    )}
                  {category_name === 'ershou' && type === '免费送' && (
                    <p className="feeds-topic-cost">免费赠送</p>
                  )}
                  {category_name === 'gongzuo' && salary && (
                    <p className="feeds-topic-cost">
                      {this.renderSalaryTitle(salary)}
                    </p>
                  )}
                </div>
              )}
            </div>
            {(content && content.length > 0) ||
            (images && images.length > 0) ? (
              <div className="feeds-topic-main">
                <div className="ellipsis">
                  <div className="ellipsis-container">
                    <div className="ellipsis-content">{content}</div>
                    <div className="ellipsis-ghost">
                      <div className="ellipsis-placeholder" />
                      <div className="ellipsis-more">
                        {content && '...详情'}
                      </div>
                    </div>
                  </div>
                </div>
                {images && images.length > 0 && images.length !== 1 ? (
                  <div className="feeds-topic-image-square">
                    {images
                      .slice(
                        0,
                        merchant && category_name === 'cheliang' ? 6 : 9
                      )
                      .map((image, i) => (
                        <div
                          key={i}
                          className="feeds-topic-image-square-item"
                          onClick={e => this.openLightbox(e, i)}
                        >
                          <LazyLoadImage
                            alt={''}
                            src={getImageMobileList(image)}
                          />
                        </div>
                      ))}
                  </div>
                ) : (
                  <div className="feeds-topic-image">
                    {images && images.length === 1 && (
                      <div className="feeds-topic-image-one">
                        <LazyLoadImage
                          onClick={e => this.openLightbox(e)}
                          src={getImageMobileList(images[0])}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </Link>
        <Comment
          onClick={() => {
            Router.push(
              `/topic/[id]#comment-area`,
              `/topic/${id}#comment-area`
            );
          }}
        >
          <span className="iconfont icon-pinglun comment-icon" />
          {comments_count ? comments_count : '评论'}
        </Comment>
        {comment && (
          <FeedComments
            onItemClick={() => {
              Router.push(
                `/topic/[id]#comment-area`,
                `/topic/${id}#comment-area`
              );
            }}
            commentContent={comment.content}
            commentsCount={comments_count}
            name={comment.user ? comment.user.name : ''}
          />
        )}
      </FeedsCardContainer>
    );
  }
}

export default FeedsCard;
